import {supabase} from "@/supabase.ts";
import {IEcomailSubscriberData} from "@model/ecomail.ts";
import {SUPABASE_PROJECT_URL} from "@/constants/variables.ts";
import {getCookie} from "@/util/cookie.ts";
import {SUGGESTION_DELIMITER} from "@backend/functions/_shared/model/index.ts";


export async function postRequest(url, body = {}, options = {}): Promise<Response> {
    const session = await supabase.auth.getSession();
    let accessToken = session?.data?.session?.access_token;

    return fetch(`${SUPABASE_PROJECT_URL}/functions/v1/${url}`, {
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        },
        method: "POST",
        body: JSON.stringify(body),
        ...options,
    });
}

export async function getHttpAIStream(functionName: string, {
                                          postParameters,
                                          ...filterData
                                      }, callbackOnInit,
                                      callbackOnIteration,
                                      callbackOnFinish,
                                      callbackOnError) {
    callbackOnInit();
    const controller = new AbortController();
    const signal = controller.signal;

    let response;
    let done = false;

    try {
        response = await postRequest(functionName, {
            ...postParameters,
            ...filterData,
        }, {signal});

        if (!response.ok) {
            return response;
        }
    } catch (e) {
        console.error(e);
        return response;
    }

    const data = response.body;

    if (!data) {
        console.error(`No Stream`);
        return;
    }

    const reader = data.getReader();
    const decoder = new TextDecoder();

    while (!done) {
        const {value, done: doneReading} = await reader.read();

        done = doneReading;
        const chunkValue = decoder.decode(value);
        const keepGenerating = callbackOnIteration(chunkValue)

        if (!keepGenerating) {
            done = true;
            controller.abort();
        }
    }

    callbackOnFinish();

    return response;
}

interface PaymentComgateResponse {
    code: string,
    message: string,
    redirect: string,
    transId: string
}

export interface IInvoiceDetails {
    fullName: string,
    email: string,
    phone?: string,
    country: string,
    city: string,
    postalCode: string,
    address1: string,
    isCompany: boolean,
    companyName: string,
    ic: string,
    dic: string,
}

export async function httpChangeSubscription(priceId: string, {immediate}): Promise<PaymentComgateResponse> {
    const paymentResponse = await postRequest('stripe/payment/change', {
        priceId,
        immediate,
    });

    return paymentResponse.json();
}

export async function httpRenewSubscription(subscriptionType: string): Promise<PaymentComgateResponse> {
    const paymentResponse = await postRequest('payment/renew', {
        type: subscriptionType,
    });

    return paymentResponse.json();
}

export async function httpDownloadInvoice(link) {
    const linkElement = document.createElement('a');

    linkElement.href = link;
    linkElement.download = `Invoice.pdf`;

    linkElement.click();
}

export async function updateUserInfo({fullName}) {
    const {data: {user: {id}}} = await supabase.auth.getUser();

    return supabase.from('profile')
        .update({full_name: fullName})
        .eq('id', id)
}

export async function addEmailSubscriber(payload: IEcomailSubscriberData) {
    return postRequest(`mail/subscriber/add`, payload);
}

export async function updateMailSubscriberByEmail(email: string, payload: IEcomailSubscriberData) {
    return postRequest(`mail/subscriber/update`, {
        email,
        ...payload,
    });
}

export async function updateGATrackingId() {
    const GATrackingId = getCookie('_ga');
    const {data: {user: {id}}} = await supabase.auth.getUser();

    return supabase.from('profile')
        .update({ga_id: GATrackingId})
        .eq('id', id)
}

export interface IFeedbackPayload{
    responseId: number,
    feedbackOk: boolean
    feedbackText?: string
    feedbackHelpful?: boolean
    feedbackTruthful?: boolean
}

export async function saveFeedback({ responseId, feedbackOk, feedbackText, feedbackHelpful, feedbackTruthful }: IFeedbackPayload) {
    const payload = {
        feedback_ok: feedbackOk,
        feedback_text: feedbackText,
    }

    if (typeof feedbackHelpful !== 'undefined') {
        payload.feedback_helpful = feedbackHelpful
    }

    if (typeof feedbackTruthful !== 'undefined') {
        payload.feedback_truthful = feedbackTruthful
    }

    return supabase.from('chat_response')
        .update(payload)
        .eq('id', responseId)
}
