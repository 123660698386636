import {defineStore} from "pinia";
import {computed, Ref, ref} from "vue";
import {getMessagesByChatIdFromDB, subscribeToChatResponse, supabase} from "@/supabase.ts";
import {Status} from "@backend/functions/_shared/model/general.ts";
import {toast} from "vue3-toastify";
import {useI18n} from "vue-i18n";

export interface IChatGeneratingProgress {
    content: boolean,
    suggestions: boolean
}

export enum AIContentTypes {
    IMAGE = `image`,
    TEXT = `text`,
}

export enum OpenAIRole {
    ASSISTANT = 'assistant',
    SYSTEM = 'system',
    USER = 'user',
}

/**
 * Update prompt text - used for example for suggestions (user clicks on in and changes Text in prompt)
 */
const promptInputHandler = {
    callbackInstances: {},
    update (prompt) {
        Object.values(this.callbackInstances).forEach(f => f(prompt))
    },
    register(f: (prompt: string) => void, id) {
        this.callbackInstances[id] = f;
    }
}

export const useChatStore = defineStore('chat', () => {
    const generatingInProgress = ref<Record<string, Ref<IChatGeneratingProgress>>>({});
    const messagesByChatId = ref<{ [key: string]: any }>({});
    const chatList = ref<any[]>([]);
    const contentTypes = ref([]);
    const filterListByContentType = ref({});
    const newResponseNotifier = ref('');
    const { t } = useI18n();

    const submitSuggestion = (suggestion: string) => {
        toast.success(t('suggestion.toast.success'));
        promptInputHandler.update(suggestion);
    }

    const getMessagesByChatId = async (chatId: string) => {
        const response = await getMessagesByChatIdFromDB(chatId);

        messagesByChatId.value[chatId] = response.data.reduce((acc, curr) => {
            acc.push({
                content: curr.text,
                role: OpenAIRole.USER
            })

            let suggestions = [];

            try {
                suggestions = JSON.parse(curr.chat_response.suggestions)?.suggestions;
            } catch(e) {
                console.log(e);
            }

            acc.push({
                // @ts-ignore
                id: curr.chat_response.id,
                content: curr.chat_response.text,
                feedbackOk: curr.chat_response.feedback_ok,
                status: curr.chat_response.status,
                role: OpenAIRole.ASSISTANT,
                suggestions
            })

            return acc;
        }, []);
    }

    const createPromptByChatId = async (chatId, {
        promptText
    }) => {
        const {data: createPromptResponse} = await supabase.rpc('create_chat_prompt', {
            chat_id_input: chatId,
            content_input: promptText,
        })
            .single();

        await getMessagesByChatId(chatId);

        return createPromptResponse;
    }

    /**
     * Updates local version of response - DB is updated from backend
     * @param chatId
     * @param responseChunk - message increment
     * @param status
     * @param replaceContent
     */
    const updateLatestResponse = async ({
                                            chatId,
                                            responseChunk,
                                            status,
                                            increment = false
                                        }) => {
        const latestResponse = getLatestResponseByChatId(chatId);

        updateResponse(latestResponse, {
            responseChunk,
            status,
            increment
        });
    }

    const updateResponseById = async ({
                                          chatId,
                                          responseId,
                                          responseChunk,
                                          suggestions,
                                          status,
                                          increment = false,
                                      }) => {
        const chatResponse = messagesByChatId.value[chatId]?.find((message) => message.id === responseId);

        if (!chatResponse) return;

        updateResponse(chatResponse, {
            responseChunk,
            suggestions,
            status,
            increment
        });
    }

    const updateResponse = (response, {
        responseChunk,
        suggestions = [],
        status = Status.SUCCESS,
        increment = false,
    }) => {
        response.status = status;
        response.suggestions = suggestions;

        if (increment) {
            response.content += responseChunk;
        } else {
            response.content = responseChunk;
        }
    }

    const getLatestResponseByChatId = (chatId) => {
        const messageCountInChat = messagesByChatId.value[chatId].length - 1;

        return messagesByChatId.value[chatId][messageCountInChat];
    }

    const createNewChat = async (contentType: string) => {
        const {data: {user: {id}}} = await supabase.auth.getUser();

        return supabase
            .from('chat')
            .insert({
                uid: id,
                name: `template.${contentType}.empty`,
                content_type: contentType,
            })
            .select('id')
            .single()
    }

    const renameChatById = async (chatId: string, newName: string) => {
        const chatToRename = chatList.value.find((chat) => chat.id === parseInt(chatId))

        chatToRename.name = newName;

        return supabase
            .from('chat')
            .update({
                name: newName,
            })
            .eq('id', chatId)
    }

    const getChatList = async () => {
        const response = await supabase
            .from('chat')
            .select('id,created_at,name,content_type')
            .order('created_at', {ascending: false})

        console.log('chatlist',response);
        chatList.value = response.data;
    }

    const fetchFilterListByContentType = async () => {
        const filterResponseDB = await supabase
            .from('content_type_chat_filter')
            .select('content_type,default_value,chat_filter(name,chat_filter_value(value))');

        filterListByContentType.value = filterResponseDB.data.reduce((acc, curr) => {
            if (!acc[curr.content_type]) {
                acc[curr.content_type] = [];
            }

            acc[curr.content_type].push({
                name: curr.chat_filter.name,
                values: curr.chat_filter.chat_filter_value?.map((valueItem) => valueItem.value),
                default: curr.default_value,
            });

            console.log('acc[curr.content_type]', acc[curr.content_type]);

            return acc
        }, {});
    }

    const getChatListById = (chatId: number) => {
        return computed(() =>
            chatList.value.find((chat) => chat.id === chatId))
    }

    const deleteChatById = async (chatId: number) => {
        await supabase
            .from('chat')
            .delete()
            .eq('id', chatId);

        await getChatList();
    }

    const getContentTypes = async () => {
        const {data: contentTypesResponse} = await supabase.from('content_type')
            .select()
            .order('order', {ascending: true})

        contentTypes.value = contentTypesResponse;
    }

    const activeContentTypes = computed(() => {
        return contentTypes.value.filter((contentType) => contentType.active)
    })

    const subscribeResponse = async () => {
        const onChangeCallback = (responsePayload) => {
            const newResponse = responsePayload.new;
            let suggestions = [];

            try {
                suggestions = JSON.parse(newResponse.suggestions)?.suggestions
            } catch(e) {
                console.log('Unable to parse suggestions', newResponse.suggestions);
            }

            updateResponseById({
                chatId: newResponse.chat_id,
                responseId: newResponse.id,
                status: newResponse.status,
                suggestions,
                responseChunk: newResponse.text,
            })
        }

        await subscribeToChatResponse(onChangeCallback);
    }

    const setMessageFeedbackById = (chatId: number, responseId: number, feedbackOk: boolean) => {
        const message = messagesByChatId.value[chatId].find(message => message.id === responseId);

        message.feedbackOk = feedbackOk;
    }

    // @ts-ignore
    return {
        newResponseNotifier,
        messagesByChatId,
        contentTypes,
        activeContentTypes,
        chatList,
        filterListByContentType,
        generatingInProgress,
        promptInputHandler,

        submitSuggestion,
        renameChatById,
        fetchFilterListByContentType,
        getMessagesByChatId,
        getContentTypes,
        getChatList,
        getChatListById,
        createNewChat,
        deleteChatById,
        createPromptByChatId,
        updateLatestResponse,
        subscribeResponse,
        setMessageFeedbackById
    }
})
