import {reactive} from "vue";
import {useI18n} from "vue-i18n";
import {AIContentTypes, OpenAIRole, useChatStore} from "@/store/chat.ts";
import {toast} from "vue3-toastify";
import {getHttpAIStream, postRequest} from "@/services/http.service.ts";
import {storeToRefs} from "pinia";
import {useTokenStore} from "@/store/token.ts";
import {ImageExceptionOverTrialCount} from "@backend/functions/_shared/error/index.ts";
import {Status} from '@backend/functions/_shared/model/general.ts'

const generatedData = reactive({
    error: '',
});

export function useChatGenerate() {
    const chatStore = useChatStore();
    const tokenStore = useTokenStore();
    const {getImageGeneratedCount} = tokenStore;

    const {messagesByChatId, generatingInProgress} = storeToRefs(chatStore);
    const {t} = useI18n();

    const generateImage = async (chatId, responseId, {
        messages,
        ...filterData
    }) => {
        const flatMessageArray = messages
            .filter((message) => message.role === OpenAIRole.USER)
            .map((message) => message.content)

        const res = await postRequest('image/generate', {
            messages: flatMessageArray,
            responseId,
            ...filterData,
        });

        let {ok, code, image} = await res.json();

        if (!ok) {
            toast.error(t(code));

            if (code === ImageExceptionOverTrialCount.code) {
                await getImageGeneratedCount();
            }
        }

        generatingInProgress.value[chatId].content = false;
        messagesByChatId.value[chatId][messagesByChatId.value[chatId].length - 1].content = image;
        messagesByChatId.value[chatId][messagesByChatId.value[chatId].length - 1].status = Status.SUCCESS;

        return;
    }

    const stopGenerating = (chatId: string) => {
        generatingInProgress.value[chatId].content = false;
        // generatingInProgress.value[chatId].suggestions = false;
    }

    const generateText = async (chatId, responseId, {
        messages,
        contentType,
        ...filterData
    }) => {
        const onInit = () => {
        };

        const onIteration = (chunk: string, {isSuggestionChunk} = {isSuggestionChunk: false}) => {
            if (isSuggestionChunk) {
                // generatingInProgress.value[chatId].suggestions = true;
                generatingInProgress.value[chatId].content = false;
            } else {
                chatStore.updateLatestResponse({
                    chatId,
                    status: null,
                    responseChunk: chunk,
                    increment: true,
                });
            }

            return generatingInProgress.value[chatId].content
        };

        const onFinished = async () => {
            generatingInProgress.value[chatId].content = false;
            // generatingInProgress.value[chatId].suggestions = false;

            await tokenStore.getTokens();
        }

        const onError = (e) => {
            toast.error(t(`error.${e.code}`));

            generatedData.error = e.code;
            generatingInProgress.value[chatId].content = false;
        }

        return getHttpAIStream('chat',
            { postParameters: {messages, contentType, responseId}},
            onInit,
            onIteration,
            onFinished,
            onError
        );
    }

    const generateContentByType = async (chatId, contentType, {
        promptText,
        ...filterData
    }) => {
        generatingInProgress.value[chatId] = {
            content: true,
            suggestions: false
        };

        const {response_id: responseId} = await chatStore.createPromptByChatId(chatId, {
            promptText
        });

        const messages = messagesByChatId.value[chatId]
            .map((message) => ({content: message.content, role: message.role}));

        if (messages.length === 2) {
            chatStore.renameChatById(chatId, promptText);
        }

        if (contentType === AIContentTypes.IMAGE) {
            return generateImage(chatId, responseId, {
                messages,
                ...filterData
            })
        } else {
            return generateText(chatId, responseId, {
                messages,
                contentType,
                ...filterData
            })
        }
    }

    //
    // const upscaleImage = async (chatId, imageId: string) => {
    //     generatingInProgress.value.value = true;
    //
    //     const {response_id: responseId} = await chatStore.createPromptByChatId(chatId, {
    //         promptText: t('ui.image.upscale.content')
    //     });
    //
    //     const res = await postRequest('image/upscale', {
    //         id: imageId,
    //         scale: 2,
    //         responseId,
    //     });
    //
    //     const {output, status} = await res.json();
    //
    //     if (status === Status.SUCCESS) {
    //         toast.success(t(`ui.image.upscale.success`));
    //         setTimeout(async () => {
    //             await chatStore.updateLatestResponse({
    //                 chatId,
    //                 status: 'success',
    //                 responseChunk: output,
    //             });
    //         }, 1500);
    //     } else {
    //         toast.error(t(`ui.image.upscaling.error`));
    //         await chatStore.updateLatestResponse({
    //             chatId,
    //             responseChunk: `ui.image.upscaling.error`,
    //             status: Status.FAILED
    //         });
    //     }
    //
    //     generatingInProgress.value.value = false;
    // }

    return {
        generatingInProgress,
        generatedData,
        stopGenerating,
        generateContentByType
    }
}
