import {defineStore} from "pinia";
import {computed, ref} from "vue";
import {supabase} from "@/supabase.ts";


export const useUIStore = defineStore('ui', () => {
    const sideMenuVisible = ref(false);
    const latestVersion = ref('');
    const installedVersion = ref(import.meta.env.VITE_APP_VERSION);
    const appReady = ref(false);

    function toggleSideMenu(value) {
        if (document.body.clientWidth <= 768) {
            setTimeout(() => {
                sideMenuVisible.value = value ?? !sideMenuVisible.value;
            }, 10);
        }
    }

    function handleMenuToggling() {
        sideMenuVisible.value = document.body.clientWidth > 768;
    }

    async function getLatestAppVersion() {
        const { data: addData } = await supabase
            .from('app')
            .select('version')
            .order('id', { ascending: false })

        latestVersion.value = addData?.[0]?.version;
    }

    const isLatestVersion = computed(() => {
        if (!installedVersion.value || !latestVersion.value) return true;

        return installedVersion.value === latestVersion.value;
    });

    function updateVersion() {
        window.location.reload()
    }

    window.addEventListener('resize', () => {
        handleMenuToggling();
    });

    window.addEventListener('load', () => {
        handleMenuToggling();
    });

    return {
        sideMenuVisible,
        latestVersion,
        installedVersion,
        appReady,

        updateVersion,
        toggleSideMenu,
        getLatestAppVersion,
        isLatestVersion,
    };
})
